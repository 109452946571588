import {Component, inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
    selector: 'app-snackbar-with-button',
    templateUrl: './snackbar-with-button.component.html',
    styleUrl: './snackbar-with-button.component.scss',
    standalone: false
})

export class SnackbarWithButtonComponent {
    snackBarRef = inject(MatSnackBarRef);
    data = inject(MAT_SNACK_BAR_DATA);
}
