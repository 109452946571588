import { throwError,  Observable ,  Subject, BehaviorSubject, defer } from 'rxjs';
import { catchError, map, debounceTime, delay, tap, finalize } from 'rxjs/operators';
import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '../../api/api.module';
import { GetOptions, GetBlobOptions } from '../models';
import { CookieService } from 'ngx-cookie';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { ApiError } from '../../core/dtos/api';

/**
 * This class automatically adds authorization headers to the outgoing HTTP requests.
 * If there is a need to make a request that does not append those headers, please use
 * the HttpClient class directly to make those requests.
 *
 * Be advised - instances of HttpHeaders are immutable as of Angular 5.1.0.
 */
@Injectable({
  providedIn: 'root'
})
export class DashboardHttpService {
  private defaultGetOptions: GetOptions = {
    observe: 'body',
    responseType: 'json',
    saveAuthorizationCookie: true,
    checkAuthenticationMaybeRedirect: true,
    // do not merge default headers with passed option headers.
    excludeDefaultHeaders: false,
    indicateLoading: true
  };

  private defaultGetBlobOptions: GetBlobOptions = {
    observe: 'response',
    responseType: 'blob',
    saveAuthorizationCookie: true,
    checkAuthenticationMaybeRedirect: true,
    // do not merge default headers with passed option headers.
    excludeDefaultHeaders: false,
    indicateLoading: true
  };

  public requestStream = new Subject<string>();
  private globalLoadingCount$ = new BehaviorSubject(0);
  public readonly showGlobalLoading$ = this.globalLoadingCount$.pipe(map(x => x > 0));

  constructor(private http: HttpClient, private apiService: ApiService,
              private cookieService: CookieService,
              @Optional() private router: Router) {
      this.requestStream.pipe(
        debounceTime(5000))
        .subscribe(response => {
          this.resetSessionTime()
            .subscribe((newTokenResponse: string) => {
              this.apiService.userAuthorization = newTokenResponse;
          },
          (errorResponse: HttpErrorResponse) => {
            if (errorResponse.status > 0) {
              this.removeAuthAndFullNavLogout(false);
            }
          });
        });
    }

  private globalLoadingCount(x: -1 | 1, indicateLoading: boolean) {
    if (indicateLoading) {
      this.globalLoadingCount$.next(this.globalLoadingCount$.value + x);
    }
  }

  private mergeHeaders(options: GetOptions | GetBlobOptions) {
    return options.excludeDefaultHeaders ? options.headers ?? new HttpHeaders() :
      (options.headers && options.headers.keys() || []).reduce((acc, key) => acc.set(key, options.headers.get(key)), this.apiService.requestOptions.headers);
  }

  public get<T>(url: string, saveAuthorizationCookie = true, customHeaders: HttpHeaders = null, responseTypeValue = 'json'): Observable<T> {
    let headersValue: HttpHeaders = customHeaders ? customHeaders : this.apiService.requestOptions.headers;
    return defer(() => {
      this.globalLoadingCount(1, true);
      return this.http.get<T>(url, {headers: headersValue, responseType: responseTypeValue as 'json'});
    }).pipe(map((response: T) => {
      if (saveAuthorizationCookie) {
        this.requestStream.next(url);
      }
      return response;
    }),
    catchError((response: HttpErrorResponse) => {
      this.checkAuthenticationMaybeRedirect(response);
      let reshapedResponse = this.handlePickerServerErrors(response);
      return throwError(() => reshapedResponse);
    }),
    finalize(() => this.globalLoadingCount(-1, true)));
  }

  /**
   * @description This method makes a request with passed options. It will merge existing headers with
   * passed headers. If you just want the passed headers to be used pass true for the excludeDefaultHeaders option.
   * @param url url of the get endpoint.
   * @param options options to be used when hitting the endpoint.
   */
  public getWithOptions<T>(url: string, options: GetOptions = {}): Observable<T> {
    let mergedHeaders = this.mergeHeaders(options);

    let mergedOptions = { ...this.defaultGetOptions, ...options, headers: mergedHeaders };
    return defer(() => {
      this.globalLoadingCount(1, mergedOptions.indicateLoading);
      return this.http.get<T>(url, mergedOptions);
    }).pipe(map((response: T) => {
      if (mergedOptions.saveAuthorizationCookie) {
        this.requestStream.next(url);
      }
      return response;
    }),
    catchError((response: HttpErrorResponse) => {
      if (mergedOptions.checkAuthenticationMaybeRedirect) {
        this.checkAuthenticationMaybeRedirect(response);
      }
      let reshapedResponse = this.handlePickerServerErrors(response);
      return throwError(() => reshapedResponse);
    }),
    finalize(() => this.globalLoadingCount(-1, mergedOptions.indicateLoading)));
  }

  /**
   * @description This method makes a request with passed options. It will merge existing headers with
   * passed headers. If you just want the passed headers to be used pass true for the excludeDefaultHeaders option.
   * @param url url of the get endpoint.
   * @param options options to be used when hitting the endpoint.
   */
   public getBlobWithOptions(url: string, options: GetBlobOptions = { observe: 'response', responseType: 'blob' }) {
    let mergedHeaders = this.mergeHeaders(options);

    let mergedOptions = { ...this.defaultGetBlobOptions, ...options, headers: mergedHeaders };
    return defer(() => {
      this.globalLoadingCount(1, mergedOptions.indicateLoading);
      return this.http.get(url, mergedOptions);
    }).pipe(map((response) => {
        if (mergedOptions.saveAuthorizationCookie) {
          this.requestStream.next(url);
        }
        return response.body;
      }),
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (mergedOptions.checkAuthenticationMaybeRedirect) {
          this.checkAuthenticationMaybeRedirect(httpErrorResponse);
        }

        try {
          return (<Promise<any>> httpErrorResponse.error.text()).then(x => Promise.reject(<ApiError> JSON.parse(x)));
        } catch (e) {
          let apiError: ApiError = {
            error: 'Internal Error Occurred',
            error_key: 'Internal Error Occurred',
            exception: String(e),
          };

          return throwError(apiError);
        }
      }),
      finalize(() => this.globalLoadingCount(-1, mergedOptions.indicateLoading)));
  }

  /**
   * @description This method makes a get request with passed options. It will merge existing headers with
   * passed headers. If you just want the passed headers to be used pass true for the excludeDefaultHeaders option.
   * @param url url of the get endpoint.
   * @param filename the file name for the a-tag.
   * @param options options to be used when hitting the endpoint.
   */
  public downloadBlobWithOptions(url: string, filename: string, options: GetBlobOptions = { observe: 'response', responseType: 'blob' }, timeout?: number) {
    let mergedHeaders = this.mergeHeaders(options);

    let mergedOptions = { ...this.defaultGetBlobOptions, ...options, headers: mergedHeaders };
    return defer(() => {
      this.globalLoadingCount(1, mergedOptions.indicateLoading);
      return this.http.get(url, mergedOptions);
    }).pipe(map((response) => {

        if (mergedOptions.saveAuthorizationCookie) {
          this.requestStream.next(url);
        }
        return response;
      }),
      map(response => {
        // method for downloading files via ajax comes from looking at filesaver.js code
        let a = document.createElement('a');
        a.download = filename;
        a.href = URL.createObjectURL(response.body);
        return a;
      }),
      delay(0),
      tap(a => {
        setTimeout(() => URL.revokeObjectURL(a.href), timeout || 40000); // 40s
        a.dispatchEvent(new MouseEvent('click'));
      }),
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (mergedOptions.checkAuthenticationMaybeRedirect) {
          this.checkAuthenticationMaybeRedirect(httpErrorResponse);
        }

        try {
          return Promise.reject(<ApiError>httpErrorResponse.error);
        } catch (e) {
          let apiError: ApiError = {
            error: 'Internal Error Occurred',
            error_key: 'Internal Error Occurred',
            exception: String(e),
          };

          return throwError(() => apiError);
        }
      }),
      finalize(() => this.globalLoadingCount(-1, mergedOptions.indicateLoading)));
  }

  /**
   * @description This is a method that bypasses the API download service and just executes the download using filesaver
   * @param url string of the url path to the file in s3
   * @param fileName string representing the name of the file when downloaded
   */
  public simpleDownload(url: string, fileName: string) {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = fileName;
    a.click();
    document.body.removeChild(a);
  }

  /**
   * @description This method makes a post request with passed options. It will merge existing headers with
   * passed headers. If you just want the passed headers to be used pass true for the excludeDefaultHeaders option.
   * @param url url of the get endpoint.
   * @param body the body payload for the post.
   * @param filename the file name for the a-tag.
   * @param options options to be used when hitting the endpoint.
   */
  public downloadPostBlobWithOptions(url: string, body: any, filename: string, options: GetBlobOptions = { observe: 'response', responseType: 'blob' }) {
    let mergedHeaders = this.mergeHeaders(options);

    let mergedOptions = { ...this.defaultGetBlobOptions, ...options, headers: mergedHeaders };
    return defer(() => {
      this.globalLoadingCount(1, mergedOptions.indicateLoading);
      return this.http.post(url, body, mergedOptions);
    }).pipe(map((response) => {
        if (mergedOptions.saveAuthorizationCookie) {
          this.requestStream.next(url);
        }
        return response;
      }),
      map(response => {
        // method for downloading files via ajax comes from looking at filesaver.js code
        let a = document.createElement('a');
        a.download = filename;
        a.href = URL.createObjectURL(response.body);
        return a;
      }),
      delay(0),
      tap(a => {
        setTimeout(() => URL.revokeObjectURL(a.href), 40000); // 40s
        a.dispatchEvent(new MouseEvent('click'));
      }),
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (mergedOptions.checkAuthenticationMaybeRedirect) {
          this.checkAuthenticationMaybeRedirect(httpErrorResponse);
        }

        try {
          return (<Promise<any>> httpErrorResponse.error.text()).then(x => Promise.reject(<ApiError> JSON.parse(x)));
        } catch (e) {
          let apiError: ApiError = {
            error: 'Internal Error Occurred',
            error_key: 'Internal Error Occurred',
            exception: String(e),
          };

          return throwError(() => apiError);
        }
      }),
      finalize(() => this.globalLoadingCount(-1, mergedOptions.indicateLoading)));
  }

  public put<Rq, Rs>(url: string, body: Rq, saveAuthorizationCookie = true): Observable<Rs> {
    return defer(() => {
      this.globalLoadingCount(1, true);
      return this.http.put<Rs>(url, body, {headers: this.apiService.requestOptions.headers});
    }).pipe(
      map((response: Rs) => {
        if (saveAuthorizationCookie) {
          this.requestStream.next(url);
        }
        return response;
      }),
      catchError((response: HttpErrorResponse) => {
        this.checkAuthenticationMaybeRedirect(response);
        let reshapedResponse = this.handlePickerServerErrors(response);
        return throwError(() => reshapedResponse);
      }),
      finalize(() => this.globalLoadingCount(-1, true)));
  }

  public post<Rq, Rs>(url: string, body: Rq, saveAuthorizationCookie = true): Observable<Rs> {
    return defer(() => {
      this.globalLoadingCount(1, true);
      return this.http.post<Rs>(url, body, {headers: this.apiService.requestOptions.headers});
    }).pipe(
      map((response: Rs) => {
        if (saveAuthorizationCookie) {
          this.requestStream.next(url);
        }
        return response;
      }),
      catchError((response: HttpErrorResponse) => {
        this.checkAuthenticationMaybeRedirect(response);
        let reshapedResponse = this.handlePickerServerErrors(response);
        return throwError(() => reshapedResponse);
      }),
      finalize(() => this.globalLoadingCount(-1, true)));
  }

  /**
   * @description This method makes a request with passed options. It will merge existing headers with
   * passed headers. If you just want the passed headers to be used pass true for the excludeDefaultHeaders param.
   * @param url url of the post endpoint.
   * @param body body of the post endpoint request.
   * @param options options to be used when hitting the endpoint.
   */
  public postWithOptions<T>(url: string, body: any, options: GetOptions = {}): Observable<T> {
    let mergedHeaders = this.mergeHeaders(options);

    let mergedOptions = { ...this.defaultGetOptions, ...options, headers: mergedHeaders };
    return defer(() => {
      this.globalLoadingCount(1, mergedOptions.indicateLoading);
      return this.http.post<T>(url, body, mergedOptions);
    }).pipe(map((response: T) => {
        if (mergedOptions.saveAuthorizationCookie) {
          this.requestStream.next(url);
        }
        return response;
      }),
      catchError((response: HttpErrorResponse) => {
        if (mergedOptions.checkAuthenticationMaybeRedirect) {
          this.checkAuthenticationMaybeRedirect(response);
        }
        let reshapedResponse = this.handlePickerServerErrors(response);
        return throwError(() => reshapedResponse);
      }),
      finalize(() => this.globalLoadingCount(-1, mergedOptions.indicateLoading))
    );
  }

  /**
   * @description This method makes a request with passed options. It will merge existing headers with
   * passed headers. If you just want the passed headers to be used pass true for the excludeDefaultHeaders param.
   * @param url url of the put endpoint.
   * @param body body of the put endpoint request.
   * @param options options to be used when hitting the endpoint.
   */
  public putWithOptions<T>(url: string, body: any, options: GetOptions = {}): Observable<T> {
    let mergedHeaders = this.mergeHeaders(options);

    let mergedOptions = { ...this.defaultGetOptions, ...options, headers: mergedHeaders };
    return defer(() => {
      this.globalLoadingCount(1, mergedOptions.indicateLoading);
      return this.http.put<T>(url, body, mergedOptions);
    }).pipe(map((response: T) => {
        if (mergedOptions.saveAuthorizationCookie) {
          this.requestStream.next(url);
        }
        return response;
      }),
      catchError((response: HttpErrorResponse) => {
        if (mergedOptions.checkAuthenticationMaybeRedirect) {
          this.checkAuthenticationMaybeRedirect(response);
        }
        let reshapedResponse = this.handlePickerServerErrors(response);
        return throwError(() => reshapedResponse);
      }),
      finalize(() => this.globalLoadingCount(-1, mergedOptions.indicateLoading)));
  }

  public delete<T>(url: string, saveAuthorizationCookie = true): Observable<T> {
    return defer(() => {
      this.globalLoadingCount(1, true);
      return this.http.delete(url, {headers: this.apiService.requestOptions.headers});
    }).pipe(
      map((response: T) => {
        if (saveAuthorizationCookie) {
          this.requestStream.next(url);
        }
        return response;
      }),
      catchError((response: HttpErrorResponse) => {
        this.checkAuthenticationMaybeRedirect(response);
        let reshapedResponse = this.handlePickerServerErrors(response);
        return throwError(() => reshapedResponse);
      }),
      finalize(() => this.globalLoadingCount(-1, true)));
  }

  public deleteWithBody<Rq, T>(url: string, body: Rq, saveAuthorizationCookie = true): Observable<T> {
    return defer(() => {
      this.globalLoadingCount(1, true);
      return this.http.request('delete', url, {body, headers: this.apiService.requestOptions.headers});
    }).pipe(
      map((response: T) => {
        if (saveAuthorizationCookie) {
          this.requestStream.next(url);
        }
        return response;
      }),
      catchError((response: HttpErrorResponse) => {
        this.checkAuthenticationMaybeRedirect(response);
        return throwError(response);
      }),
      finalize(() => this.globalLoadingCount(-1, true)));
  }

  public resetSessionTime(): Observable<string> {
    this.apiService.appendHeader('X-Refresh-Authorization', '1');
    return this.http.get<{authorization: string}>(
      environment.apiUrl + '/noop',
      { headers: this.apiService.requestOptions.headers, observe: 'response'}
      )
      .pipe(map((response) => {
        this.apiService.removeHeader('X-Refresh-Authorization');
        return response.headers.get('Authorization');
    }),
    catchError((response: HttpErrorResponse) => {
      this.apiService.removeHeader('X-Refresh-Authorization');
      return throwError(() => response);
    }));
  }

  // The picker server returns a non-standard error if the server is down.
  // This catches any of those and converts them to conform to the HttpErrorResponse type.
  private handlePickerServerErrors(errorResponse: HttpErrorResponse): HttpErrorResponse {
    if (errorResponse.status > 499 && errorResponse.status < 600) {
      try {
        JSON.parse(errorResponse.error);
      } catch (e) {
        errorResponse = new HttpErrorResponse({
          error: { error: 'Internal Error Occurred' },
          headers: errorResponse.headers,
          status: errorResponse.status,
          statusText: errorResponse.statusText,
          url: errorResponse.url
        });
      }
    }
    return errorResponse;
  }

  public removeAuthAndFullNavLogout(displaySessionTimeout: boolean) {
    this.cookieService.remove(this.apiService.authorizationHeaderName);
    this.cookieService.remove(this.apiService.authorizationRoleName);
    this.apiService.userAuthorization = null;
    window.location.href = environment.dashboardUrl + '/assets/logout.html' + (displaySessionTimeout ? '?sessionTimedOut=true' : '');
  }

  public readonly loginStartPaths = ['/login', '/unsubscribe-report', '/notifications/accept-terms', '/affiliate-application-verification', '/tracking-test-link'];
  public readonly shopifyStartPath = '/shopify';

  private checkAuthenticationMaybeRedirect(response: HttpErrorResponse) {
    if ((!this.loginStartPaths.some(x => window.location.pathname.startsWith(x)) && !window.location.pathname.startsWith(this.shopifyStartPath))
      && (response.status === 401 || (response.status === 500 && response.error.exception === 'InvalidArgumentException'))) {

      this.cookieService.remove(this.apiService.authorizationHeaderName);
      this.apiService.userAuthorization = null;
      if (environment.isClassic) {
        window.location.href = `${environment.dashboardUrl}/login?redirect=${window.location.pathname}&isClassic=true`;
      } else {
        this.router.navigate(['login'], {queryParams: { redirect: window.location.pathname, isClassic: false}});
      }
    }
  }
}
