import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  NotificationMessage, NotificationMessageMeta, NotificationEntity, CreateNotificationMessageRequest,
  CreateNotificationEntity, NotificationEntityUser, Notification, NotificationStatus, TermsVerification,
  NotificationStatusUpdate
} from '../../dtos/api';
import { DashboardHttpService } from '../../../core-services/dashboard-http-service/dashboard-http.service';
import { switchMap, shareReplay } from 'rxjs/operators';
import { notificationTypeTags } from '../../notification';


@Injectable({
  providedIn: 'root'
})
export class NotificationApiService {

  constructor(private http: DashboardHttpService) { }

  readonly refreshUserAnnouncementMessages$ = new BehaviorSubject<void>(null);
  readonly userAnnouncementMessages$ = this.refreshUserAnnouncementMessages$.pipe(
    switchMap(() => this.fetchUserMessages(notificationTypeTags.Announcement, ['created', 'displayed'])),
    shareReplay(1));
  readonly refreshUserUnseenAnnouncements$ = new BehaviorSubject<void>(null);
  readonly userUnseenAnnouncements$ = this.refreshUserAnnouncementMessages$.pipe(
    switchMap(() => this.fetchUserMessages(notificationTypeTags.Announcement, ['created'])),
    shareReplay(1));

  private notificationsPath = `${environment.apiUrl}/notifications`;

  fetchMessage(notificationsMessagesId: string): Observable<NotificationMessage> {
    return this.http.get(`${this.notificationsPath}/message/${notificationsMessagesId}`);
  }

  deleteMessage(notificationsMessageId: string): Observable<NotificationMessage> {
    // this marks the message as deleted along with the entities assigned to the messages
    return this.http.delete(`${this.notificationsPath}/message/${notificationsMessageId}`);
  }

  fetchAllMessages(): Observable<NotificationMessageMeta[]> {
    return this.http.get(`${this.notificationsPath}/messages`);
  }

  fetchAllEntities(): Observable<NotificationEntity[]> {
    return this.http.get(`${this.notificationsPath}/message/entities`);
  }

  fetchMessageEntities(notificationsMessagesId: string): Observable<NotificationEntity[]> {
    return this.http.get(`${this.notificationsPath}/message/${notificationsMessagesId}/entities`);
  }

  fetchUserMessages(tagId: string, status: NotificationStatus[] = null, refreshCookieTime = true): Observable<Notification[]> {
    let url = `${this.notificationsPath}/${tagId}`;
    if (status) {
      url += `/${status.join(',')}`;
    }
    return this.http.get(url, refreshCookieTime);
  }

  fetchEntityUsers(request: CreateNotificationEntity[]): Observable<NotificationEntityUser[]> {
    return this.http.post(`${this.notificationsPath}/message/entities/users`, request);
  }

  fetchEntityUsersCount(request: CreateNotificationEntity[]): Observable<{ count: number }> {
    return this.http.post(`${this.notificationsPath}/message/entities/users/count`, request);
  }

  createMessage(request: CreateNotificationMessageRequest): Observable<NotificationMessage> {
    return this.http.post(`${this.notificationsPath}/message`, request);
  }

  editMessage(notificationsMessageId: string, request: CreateNotificationMessageRequest): Observable<NotificationMessage> {
    return this.http.put(`${this.notificationsPath}/message/${notificationsMessageId}`, request);
  }

  assignEntitiesToMessage(notificationsMessageId: string, request: CreateNotificationEntity[]): Observable<NotificationEntity[]> {
    return this.http.post(`${this.notificationsPath}/message/${notificationsMessageId}/entities`, request);
  }

  updateMessageStatus(notificationsMessageId: string, status: NotificationStatus, refreshCookieTime = true): Observable<NotificationStatusUpdate> {
    return this.http.put(`${this.notificationsPath}/${notificationsMessageId}/status`, { status }, refreshCookieTime);
  }

  getUserTermsVerification(): Observable<TermsVerification> {
    return this.http.get<TermsVerification>(`${this.notificationsPath}/terms/verification`, false);
  }
}
