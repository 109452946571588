<div class="navigation">
  <button mat-icon-button [matMenuTriggerFor]="menu" class="hamburger"><mat-icon>menu</mat-icon></button>

  <nav mat-tab-nav-bar [tabPanel]="tabPanel" [disablePagination]="true">
    <a mat-tab-link (click)="directToDashboard()" class="tri-logo-tab">
      <img [ngSrc]="'/assets/images/logo-symbol-on-dark.svg' | absUrl" width="28" height="25">
    </a>

    <ng-container *ngIf="entityGroupName != null && permissions != null">
      <a mat-tab-link *ngIf="entityGroupName !== 'affiliates'" [href]="classicRoute + '/affiliates.php'" class="avant-full"
         [class.active]="(location.href | includes:'/affiliates.php') || (location.href | includes: '/applications.php') || (location.href | includes: '/affiliate_recruitment.php')
          || (location.href | includes : 'partners/email-partners')">
        Affiliates
      </a>

      <a mat-tab-link *ngIf="entityGroupName === 'affiliates' || entityGroupName === 'root'" [href]="classicRoute + '/merchants.php'" class="avant-full" [class.active]="location.href | includes:'/merchants.php'">
        Merchants
      </a>

      <a mat-tab-link *ngIf="entityGroupName === 'root'" [href]="classicUrl + '/admin/tools.php'" class="avant-full" [class.active]="location.href | includes:'/tools.php'">
        Tools
      </a>

      <ng-container *ngIf="entityGroupName === 'root'">
        <a mat-tab-link *ngIf="!isClassic" routerLink="/communication-tools" class="avant-full" routerLinkActive="active">
          Communication
        </a>
        <a mat-tab-link *ngIf="isClassic" [href]="'/communication-tools' | absUrl" class="avant-full" [class.active]="location.href | includes:'/moderation_queue.php'">
          Communication
        </a>
      </ng-container>

      <a mat-tab-link *ngIf="entityGroupName === 'root'" [href]="classicUrl + '/admin/account.php'" class="avant-full" [class.active]="location.href | includes:'/account.php'">
        Account
      </a>

      <ng-container *ngIf="(entityGroupName === 'merchants' || entityGroupName === 'merchant_groups' || (entityGroupName === 'affiliates' && permissions['configure_tools']))">
        <a mat-tab-link *ngIf="!isClassic" routerLink="/ad-tools" class="avant-full" routerLinkActive="active">
          Ad Tools
        </a>
        <a mat-tab-link *ngIf="isClassic" [href]="'/ad-tools' | absUrl" class="avant-full" [class.active]="location.href | includes: '/datafeed_list.php'">
          Ad Tools
        </a>
      </ng-container>

      <a mat-tab-link *ngIf="(entityGroupName !== 'affiliates' || (entityGroupName === 'affiliates' && permissions['view_reports'])) && !environment.featuresFlags.showEdw3Reports"
        [href]="classicRoute + '/reports.php'" class="avant-full" [class.active]="(location.href | includes:'/reports.php') || (location.href | includes:'/report/')">
        Reports
      </a>

      <a mat-tab-link *ngIf="(entityGroupName !== 'affiliates' || (entityGroupName === 'affiliates' && permissions['view_reports'])) && environment.featuresFlags.showEdw3Reports"
         [matMenuTriggerFor]="reportsNavMenu" class="avant-full down-arrow">
        Reports
      </a>
    </ng-container>
  </nav>
  <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>

  <mat-menu #menu="matMenu" [overlapTrigger]="false" class="mobile-menu-panel" [backdropClass]="'mobile-menu-overlay'">
    <ng-container *ngIf="entityGroupName != null && permissions != null">
      <a mat-menu-item (click)="directToDashboard()">
        <img [ngSrc]="'/assets/images/Avantlink_White.svg' | absUrl" alt="AvantLink" class="hamburger-menu-logo" height="28" width="188">
      </a>

      <a mat-menu-item *ngIf="entityGroupName !== 'affiliates'" [href]="classicRoute + '/affiliates.php'" [class.active]="location.href | includes:'/affiliates.php'">
        Affiliates
      </a>

      <a mat-menu-item *ngIf="entityGroupName === 'affiliates' || entityGroupName === 'root'" [href]="classicRoute + '/merchants.php'" [class.active]="location.href | includes:'/merchants.php'">
        Merchants
      </a>

      <ng-container *ngIf="entityGroupName === 'root'">
        <a mat-menu-item *ngIf="!isClassic" routerLink="/communication-tools" routerLinkActive="active">
          Communication
        </a>
        <a mat-menu-item *ngIf="isClassic" [href]="'/communication-tools' | absUrl" [class.active]="location.href | includes:'/communication-tools'">
          Communication
        </a>
      </ng-container>

      <a mat-menu-item *ngIf="entityGroupName === 'root'" [href]="classicUrl + '/admin/account.php'" [class.active]="location.href | includes:'/account.php'">
        Account
      </a>

      <a mat-menu-item *ngIf="entityGroupName === 'root'" [href]="classicUrl + '/admin/tools.php'" [class.active]="location.href | includes:'/tools.php'">
        Tools
      </a>

      <ng-container *ngIf="entityGroupName === 'merchants' || entityGroupName === 'merchant_groups' || (entityGroupName === 'affiliates' && permissions['configure_tools'])">
        <a mat-menu-item *ngIf="!isClassic" routerLink="/ad-tools" routerLinkActive="active">
          Ad Tools
        </a>
        <a mat-menu-item *ngIf="isClassic" [href]="'/ad-tools' | absUrl" [class.active]="location.href | includes:'/ad-tools'">
          Ad Tools
        </a>
      </ng-container>

      <a mat-menu-item *ngIf="((entityGroupName !== 'affiliates' || (entityGroupName === 'affiliates' && permissions['view_reports'])) && !environment.featuresFlags.showEdw3Reports)"
        [href]="classicRoute + '/reports.php'" [class.active]="(location.href | includes:'/report.php') || (location.href | includes:'/report/')">
        Reports
      </a>

      <a mat-menu-item *ngIf="((entityGroupName !== 'affiliates' || (entityGroupName === 'affiliates' && permissions['view_reports'])) && environment.featuresFlags.showEdw3Reports)"
         [matMenuTriggerFor]="reportsHamburgerMenu">
        Reports
      </a>

      <mat-divider></mat-divider>

      <a mat-menu-item *ngIf="entityGroupName === 'merchants' || entityGroupName === 'merchant_groups'" [href]="classicUrl + '/merchant_all/affiliate_bonus.php'" [class.active]="location.href | includes:'/affiliate_bonus.php'">
        Commission Tools
      </a>

      <a mat-menu-item *ngIf="entityGroupName === 'merchants' || entityGroupName === 'merchant_groups'" [href]="classicUrl + '/merchant_all/order_select.php'" [class.active]="location.href | includes:'/order_select.php'">
        Transaction Manager
      </a>

      <ng-container *ngIf="entityGroupName !== 'root'">
        <a mat-menu-item *ngIf="!isClassic" routerLink="/account-settings" routerLinkActive="active">
          Account Settings
        </a>
        <a mat-menu-item *ngIf="isClassic" [href]="'/account-settings' | absUrl" [class.active]="location.href | includes:'/account-settings/'">
          Account Settings
        </a>
      </ng-container>

      <a mat-menu-item *ngIf="entityGroupName === 'affiliates'" [href]="classicUrl + '/affiliate/payout_history.php?strAction=balanceSummary'" [class.active]="location.href | includes:'/payout_history.php'">
        Payment Settings
      </a>
      <a mat-menu-item *ngIf="entityGroupName === 'merchants' || entityGroupName === 'merchant_groups'" [href]="classicUrl + '/merchant_all/view_invoice_list.php'" [class.active]="location.href | includes:'/view_invoice_list.php'">
        Payment Settings
      </a>

      <a mat-menu-item *ngIf="!isClassic" routerLink="/user-settings/profile" routerLinkActive="active">
        User Settings
      </a>
      <a mat-menu-item *ngIf="isClassic" [href]="'/user-settings/profile' | absUrl" [class.active]="(location.href | includes:'/user-settings/') || (location.href | includes:'/affiliate/notifications.php') || (location.href | includes:'/merchant_all/notifications.php')">
        User Settings
      </a>

      <a mat-menu-item *ngIf="entityGroupName === 'affiliates' && permissions['view_reports']" [href]="classicUrl + '/affiliate/order_inquiry_list.php'" [class.active]="location.href | includes:'/affiliate/order_inquiry_list.php'">
        Order Inquiries
      </a>
    </ng-container>
  </mat-menu>

  <mat-menu #reportsNavMenu="matMenu" class="report-menu-panel">
    <a mat-menu-item [href]="classicRoute + '/reports.php'">Classic Reports</a>
    <a *ngIf="isClassic" mat-menu-item [href]="dashboardUrl + '/reports/performance'">Performance Reports</a>
    <a *ngIf="!isClassic" mat-menu-item routerLink="/reports/performance">Performance Reports</a>
  </mat-menu>

  <mat-menu #reportsHamburgerMenu="matMenu" class="report-menu-panel hamburger-menu-reports">
    <a mat-menu-item [href]="classicRoute + '/reports.php'">Classic Reports</a>
    <a *ngIf="isClassic" mat-menu-item [href]="dashboardUrl + '/reports/performance'">Performance Reports</a>
    <a *ngIf="!isClassic" mat-menu-item routerLink="/reports/performance">Performance Reports</a>
  </mat-menu>

  <div class="account-switcher flex-1">
    <div class="account-info" (click)="openAccountSwitcher()">
      <div class="current-entity-name">{{entityName}}</div>
      <div class="current-entity-details">
        <mat-icon [svgIcon]="(dashboardApi.currentAccount$ | async)?.entity_network"></mat-icon>
        {{ entityGroupName | accountType | titleCase }} {{(dashboardApi.currentAccount$ | async)?.classic_entity_id}}
        <mat-icon>arrow_drop_down</mat-icon>
      </div>
    </div>
    <div class="menu">
      <mat-icon *ngIf="(entityGroupName === 'affiliates' && permissions != null && permissions['manage_payout_methods']) || entityGroupName === 'merchants' || entityGroupName === 'merchant_groups'" matTooltip="Account Balance" (click)="openAccountBalance()">monetization_on</mat-icon>
      <mat-icon (click)="openUserNotifications()" [matBadge]="userAnnouncementMessages.length < 10 ? userAnnouncementMessages.length?.toString() : '9+'" [matBadgeHidden]="userAnnouncementMessages.length === 0" matBadgeColor="warn" matBadgeSize="small" matTooltip="Notifications">notifications</mat-icon>
      <mat-icon (click)="openZendeskHelp()" matTooltip="Support">help</mat-icon>
    </div>
  </div>
</div>
<app-header-sub-nav [headerTitle]="headerTitle"></app-header-sub-nav>
