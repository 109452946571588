<div class="subnav" mat-tab-nav-bar [tabPanel]="tabPanel">
    <ng-container *ngIf="environment.isClassic; else dashboardSubNav">
      <ng-container [ngSwitch]="subHeaderGrouping$ | async">
        <ng-container *ngSwitchCase="'report'">
          <a mat-tab-link class="subnav-active" href="#">
            Classic Reports
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'reports'">
          <a *ngIf="environment.featuresFlags.showEdw3Reports" mat-tab-link [routerLink]="'/reports/performance'"
             [routerLinkActive]="['subnav-active']">
            Performance
          </a>
          <a *ngIf="environment.featuresFlags.showEdw3Reports" mat-tab-link [routerLink]="'/reports/all-reports'"
             [routerLinkActive]="['subnav-active']">
            All Reports
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'userSettings'">
          <a mat-tab-link href="{{environment.dashboardUrl}}/user-settings/profile">
            Profile
          </a>
          <a mat-tab-link href="{{environment.dashboardUrl}}/user-settings/password">
            Password
          </a>
          <a mat-tab-link href="{{environment.dashboardUrl}}/user-settings/email-subscriptions">
            Email Subscriptions
          </a>
          <a mat-tab-link href="{{environment.dashboardUrl}}/user-settings/security">
            Security
          </a>
          <a mat-tab-link href="{{environment.dashboardUrl}}/user-settings/arches-settings">
            Arches Settings
          </a>
          <a *ngIf="currentAccountEntityGroupName !== 'root'"
             mat-tab-link class="subnav-active">
            Classic Notifications
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'datafeedManagement'">
          <a *ngIf="currentAccountEntityGroupName === 'affiliates'"
            mat-tab-link [ngClass]="{'subnav-active': location.href | includes: '/affiliate/configure_datafeed.php'}"
             href="{{environment.classicUrl}}/affiliate/configure_datafeed.php?strIntegrationMethod=file&blnSignup=1">
            New Datafeed
          </a>
          <a *ngIf="currentAccountEntityGroupName === 'affiliates'"
            mat-tab-link [ngClass]="{'subnav-active': location.href | includes: '/affiliate/edit_services.php'}"
             href="{{environment.classicUrl}}/affiliate/edit_services.php?Method=file">
            Edit Datafeeds
          </a>
          <a *ngIf="currentAccountEntityGroupName === 'affiliates'"
            mat-tab-link [ngClass]="{'subnav-active': location.href | includes: '/affiliate/edit_image_preferences.php'}"
             href="{{environment.classicUrl}}/affiliate/edit_image_preferences.php">
            Datafeed Preferences
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'apiManagement'">
          <a *ngIf="currentAccountEntityGroupName === 'affiliates'"
            mat-tab-link [ngClass]="{'subnav-active': location.href | includes: '/affiliate/api_request_builder.php'}"
             href="{{environment.classicUrl}}/affiliate/api_request_builder.php">
            API Request Builder
          </a>
          <a *ngIf="currentAccountEntityGroupName === 'affiliates'"
            mat-tab-link [ngClass]="{'subnav-active': location.href | includes: '/affiliate/view_edit_auth_key.php'}"
             href="{{environment.classicUrl}}/affiliate/view_edit_auth_key.php">
            API Key
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'orderInquiries'">
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'"
             [ngClass]="{'subnav-active': location.href | includes: '/affiliate/order_inquiry_list.php'}"
             href="{{environment.classicUrl}}/affiliate/order_inquiry_list.php">
            Order Inquiry
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates' && permissions.view_reports"
             [ngClass]="{'subnav-active': location.href | includes: '/affiliate/order_inquiry_batch.php'}"
             href="{{environment.classicUrl}}/affiliate/order_inquiry_batch.php">
            Batch Order Inquiry
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'"
             [ngClass]="{'subnav-active': location.href | includes: '/affiliate/tracking_parser.php'}"
             href="{{environment.classicUrl}}/affiliate/tracking_parser.php">
            Tracking Details
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'paymentSettings'">
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'"
             [ngClass]="{'subnav-active': location.href | includes: '/affiliate/payout_history.php?strAction=balanceSummary'}"
             href="{{environment.classicUrl}}/affiliate/payout_history.php?strAction=balanceSummary">
            Balance Summary
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'"
             [ngClass]="{'subnav-active': ((location.pathname + location.search) === '/affiliate/payout_history.php')
             || (location.href | includes: '/affiliate/payout_history.php?strAction=payoutSummary')}"
             href="{{environment.classicUrl}}/affiliate/payout_history.php">
            Payout History
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'"
             [ngClass]="{'subnav-active': pagePathName === '/affiliate/payout_tipalti.php'}"
             href="{{environment.classicUrl}}/affiliate/payout_tipalti.php">
            Payment & Tax
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'"
             [ngClass]="{'subnav-active': pagePathName === '/affiliate/withdraw_now.php'}"
             href="{{environment.classicUrl}}/affiliate/withdraw_now.php">
            Withdraw Now
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/view_invoice_list.php'}"
             href="{{environment.classicUrl}}/merchant_all/view_invoice_list.php">
            Invoices
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': (location.href | includes:'/merchant_all/pay_invoices.php')
             || (location.href | includes:'/merchant_all/ebp_payment.php')
             || (location.href | includes:'/merchant_all/ebp_payment_credit.php')}"
             href="{{environment.classicUrl}}/merchant_all/pay_invoices.php">
            Pay Invoice
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.pathname + location.search ==='/merchant_all/ebp_setup.php'}"
             href="{{environment.classicUrl}}/merchant_all/ebp_setup.php">
            Auto Payment
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'accountSettings'">
          <a *ngIf="(permissions.manage_merchant_relationships && currentAccountEntityGroupName === 'affiliates')
            || (currentAccountEntityGroupName === 'merchants' && permissions.merchant_access)
            || (currentAccountEntityGroupName === 'merchant_groups' && permissions.merchant_access)" mat-tab-link
             href="{{environment.dashboardUrl}}/account-settings/account-profile"
             [ngClass]="{'subnav-active': location.href | includes: '/account-settings/account-profile'}">
            Account Profile
          </a>
          <a *ngIf="(permissions.create_users && currentAccountEntityGroupName === 'affiliates')
            || ((currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups')
            && permissions.create_users)"
             mat-tab-link href="{{environment.dashboardUrl}}/account-settings/manage-users"
             [ngClass]="{'subnav-active': location.href | includes: '/account-settings/manage-users'}">
            Manage Users
          </a>
          <a mat-tab-link *ngIf="permissions.create_users && currentAccountEntityGroupName === 'affiliates'"
             href="{{environment.classicUrl}}/affiliate/edit_websites.php"
             [ngClass]="{'subnav-active': location.href | includes: '/affiliate/edit_websites.php'}">
            Secondary Websites
          </a>
          <a mat-tab-link *ngIf="permissions.view_reports && currentAccountEntityGroupName === 'affiliates'" href="{{environment.classicUrl}}/affiliate/tag_group_admin.php"
             [ngClass]="{'subnav-active': location.href | includes: '/affiliate/tag_group_admin.php'}">
            Tag Groups
          </a>
          <a mat-tab-link *ngIf="permissions.create_users && currentAccountEntityGroupName ==='affiliates'"
             href="{{environment.dashboardUrl}}/account-settings/avantlink-networks"
             [ngClass]="{'subnav-active': location.href | includes: '/account-settings/avantlink-networks'}">
            AvantLink Networks
          </a>
          <a mat-tab-link *ngIf="(currentAccountEntityGroupName === 'merchants' && permissions.merchant_access)
            || (currentAccountEntityGroupName === 'merchant_groups' && permissions.merchant_access)"
             href="{{environment.classicUrl}}/merchant_all/edit_program_terms.php"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/edit_program_terms.php'}">
            Program Terms
          </a>
          <a mat-tab-link *ngIf="(currentAccountEntityGroupName === 'merchants' && permissions.merchant_access)
            || (currentAccountEntityGroupName === 'merchant_groups' && permissions.merchant_access)"
             href="{{environment.dashboardUrl}}/account-settings/email-templates"
             [ngClass]="{'subnav-active': location.href | includes: '/account-settings/email-templates'}">
            Email Templates
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName ==='merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             href="{{environment.classicUrl}}/merchant_all/contract_list.php"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/contract_list.php'}">
            Contracts
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName ==='merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             href="{{environment.dashboardUrl}}/account-settings/integration"
             [ngClass]="{'subnav-active': location.href | includes: '/account-settings/integration'}">
            Integration
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName ==='merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             href="{{environment.classicUrl}}/merchant_all/order_restrict_ip.php"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/order_restrict_ip.php'}">
            IP Restrictions
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'commissionTools'">
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/affiliate_bonus.php'}"
             href="{{environment.classicUrl}}/merchant_all/affiliate_bonus.php">
            Bonus
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/affiliate_commission_promotion.php'}"
             href="{{environment.classicUrl}}/merchant_all/affiliate_commission_promotion.php">
            Commission Promotion
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/item_based_commission.php'}"
             href="{{environment.classicUrl}}/merchant_all/item_based_commission.php">
            Item Based Commission
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/tag_group_admin.php'}"
             href="{{environment.classicUrl}}/merchant_all/tag_group_admin.php">
            Tag Groups
          </a>
          <a mat-tab-link *ngIf="(currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups') && currentAccountEntityNetwork === 'US'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/sub_affiliate_commission.php'}"
             href="{{environment.classicUrl}}/merchant_all/sub_affiliate_commission.php">
            Sub Affiliate Partner
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/network_settings.php'}"
             href="{{environment.classicUrl}}/merchant_all/network_settings.php">
            Network Rules
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'transactionManager'">
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/order_select.php'}"
             href="{{environment.classicUrl}}/merchant_all/order_select.php">
            Returns
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/order_manual_entry.php'}"
             href="{{environment.classicUrl}}/merchant_all/order_manual_entry.php">
            Sales
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
            [ngClass]="{'subnav-active': location.href | includes: '/transaction-manager/batch-returns-sales'}"
            [href]="environment.dashboardUrl + '/transaction-manager/batch-returns-sales'">
            Batch Returns & Sales
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/order_inquiry_list.php'}"
             href="{{environment.classicUrl}}/merchant_all/order_inquiry_list.php">
            Order Inquiry
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/order_search.php'}"
             href="{{environment.classicUrl}}/merchant_all/order_search.php">
            Order Search
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/tracking_parser.php'}"
             href="{{environment.classicUrl}}/merchant_all/tracking_parser.php">
            Tracking Details
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'paidPlacement'">
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/ppb.php'}"
             href="{{environment.classicUrl}}/merchant_all/ppb.php">
            Paid Placement
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/ppb_list.php'}"
             href="{{environment.classicUrl}}/merchant_all/ppb_list.php">
            New Bid
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/paid_placement.php'}"
             href="{{environment.classicUrl}}/merchant_all/paid_placement.php">
            Learn More
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'"
             [ngClass]="{'subnav-active': (location.pathname + location.search) === '/affiliate/ppb_configure.php'}"
             href="{{environment.classicUrl}}/affiliate/ppb_configure.php">
            Paid Placement
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'"
             [ngClass]="{'subnav-active': (location.href | includes: '/affiliate/ppb_list.php')
             || (location.href | includes: '/affiliate/ppb_schedule.php')
             || (location.href | includes: '/affiliate/ppb_configure.php?lngPpbId=')
             || (location.href | includes: '/affiliate/ppb_source.php')}"
             href="{{environment.classicUrl}}/affiliate/ppb_list.php">
            Edit Paid Placement
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'"
             [ngClass]="{'subnav-active': location.href | includes: '/affiliate/ppb_bids.php'}"
             href="{{environment.classicUrl}}/affiliate/ppb_bids.php">
            Paid Placement Bids
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'linkEncoder'">
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'"
             [ngClass]="{'subnav-active': (location.pathname + location.search) === '/affiliate/affiliate_link_encoder.php'}"
             href="{{environment.classicUrl}}/affiliate/affiliate_link_encoder.php">
            Affiliate Link Encoder
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'"
             [ngClass]="{'subnav-active': (location.href | includes: '/affiliate/affiliate_link_encoder_list.php')
             || (location.href | includes: '/affiliate/affiliate_link_encoder.php?lngAleConfigId=')
             || (location.href | includes: '/affiliate/affiliate_link_encoder_source.php')}"
             href="{{environment.classicUrl}}/affiliate/affiliate_link_encoder_list.php">
            Edit Affiliate Link Encoder
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'dynamicDealFeed'">
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'"
             [ngClass]="{'subnav-active': (location.pathname + location.search) === '/affiliate/coupon_feed_signup.php'}"
             href="{{environment.classicUrl}}/affiliate/coupon_feed_signup.php">
            Dynamic Deal Feed
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'"
             [ngClass]="{'subnav-active': (location.href | includes: '/affiliate/coupon_feed_edit.php')
             || (location.href | includes: '/affiliate/coupon_feed_signup.php?lngCouponFeedId=')
             || (location.href | includes: '/affiliate/coupon_feed_source.php')}"
             href="{{environment.classicUrl}}/affiliate/coupon_feed_edit.php">
            Edit Dynamic Deal Feed
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'productAdWidget'">
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'"
             [ngClass]="{'subnav-active': (location.pathname + location.search) === '/affiliate/product_ad_widget.php'}"
             href="{{environment.classicUrl}}/affiliate/product_ad_widget.php">
            Product Ad Widget
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'"
             [ngClass]="{'subnav-active': (location.href | includes: '/affiliate/paw_edit.php')
             || (location.href | includes: '/affiliate/product_ad_widget.php?blnEditConfig=')}"
             href="{{environment.classicUrl}}/affiliate/paw_edit.php">
            Edit Product Ad Widget
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'productContentWidget'">
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'"
             [ngClass]="{'subnav-active': (location.pathname + location.search) === '/affiliate/pcw.php'}"
             href="{{environment.classicUrl}}/affiliate/pcw.php">
            Product Content Widget
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'"
             [ngClass]="{'subnav-active': (location.href | includes: '/affiliate/pcw_list.php')
             || (location.href | includes: '/affiliate/pcw.php?lngPcwId=')
             || (location.href | includes: '/affiliate/pcw_source.php')}"
             href="{{environment.classicUrl}}/affiliate/pcw_list.php">
            Edit Product Content Widget
          </a>
        </ng-container>
        <ng-container *ngSwitchCase="'communication'">
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'root'" href="{{environment.dashboardUrl}}/communication-tools/email-moderation">
            Email Moderation
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'root'" class='subnav-active' href="{{environment.classicUrl}}/admin/moderation_queue.php">
            Tag Group Rule Moderation
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'root'" href="{{environment.dashboardUrl}}/communication-tools/notifications">
            Notifications
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'root'" href="{{environment.dashboardUrl}}/communication-tools/network-emails">
            Network Email
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'root'" href="{{environment.dashboardUrl}}/communication-tools/email-templates">
            Email Templates
          </a>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <a mat-tab-link class="non-link" [disableRipple]="true" href="#">
            {{headerTitle}}
          </a>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #dashboardSubNav>
      <ng-container [ngSwitch]="subHeaderGrouping$ | async">
        <ng-container *ngSwitchCase="'report'">
            <a mat-tab-link
               href="{{routingService.classicRoute$ | async}}/reports.php"
               [routerLinkActive]="['subnav-active']">
              Classic Reports
            </a>
        </ng-container>

        <ng-container *ngSwitchCase="'reports'">
          <a *ngIf="environment.featuresFlags.showEdw3Reports" mat-tab-link [routerLink]="'/reports/performance'"
             [routerLinkActive]="['subnav-active']">
            Performance
          </a>
          <a *ngIf="environment.featuresFlags.showEdw3Reports" mat-tab-link [routerLink]="'/reports/all-reports'"
             [routerLinkActive]="['subnav-active']">
            All Reports
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'merchantDashboard'">
          <ng-container *ngIf="hasMultipleMerchants || currentAccountEntityGroupName === 'merchant_groups'; else isStandAloneMerchant">
            <a mat-tab-link [routerLink]="'/dashboard/agency'" [routerLinkActive]="['subnav-active']">
              Agency Dashboard
            </a>
            <a mat-tab-link [routerLink]="'/dashboard/merchant'" [routerLinkActive]="['subnav-active']">
              Merchant Dashboard
            </a>
          </ng-container>
          <ng-template #isStandAloneMerchant>
            <a mat-tab-link class="non-link" [routerLink]="'/dashboard/merchant'">
              Merchant Dashboard
            </a>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="'merchantTools'">
          <a mat-tab-link href="{{environment.classicUrl}}/admin/merchant_detail.php?strStatus=application-approved-active&lngMerchantId={{ merchantApiService.merchantClassicId$ | async }}" [routerLinkActive]="['subnav-active']">
            Merchant Details
          </a>
          <a mat-tab-link [routerLink]="'/admin/merchant-tools/integration-form'" [routerLinkActive]="['subnav-active']">
            Integration Form
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'adminEmailTemplates'">
          <a mat-tab-link [routerLink]="'/admin-tools/email-templates'" [routerLinkActive]="['subnav-active']">
            Email Templates
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'affiliateDashboard'">
          <a mat-tab-link class="non-link" [routerLink]="'/dashboard/affiliate'">
            Affiliate Dashboard
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'agencyDashboard'">
            <a mat-tab-link [routerLink]="'/dashboard/agency'"
               [routerLinkActive]="['subnav-active']">
              Agency Dashboard
            </a>
            <a mat-tab-link [routerLink]="'/dashboard/merchant'" [routerLinkActive]="['subnav-active']">
              Merchant Dashboard
            </a>
        </ng-container>

        <ng-container *ngSwitchCase="'adminDashboard'">
          <a mat-tab-link [routerLink]="'/dashboard/admin'"
             [routerLinkActive]="['subnav-active']">
            Admin Dashboard
          </a>
          <a mat-tab-link [routerLink]="'/dashboard/key-accounts'"
             [routerLinkActive]="['subnav-active']">
            Key Accounts
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'userSettings'">
          <a mat-tab-link [routerLink]="'/user-settings/profile'"
             [routerLinkActive]="['subnav-active']">
            Profile
          </a>
          <a mat-tab-link [routerLink]="'/user-settings/password'"
             [routerLinkActive]="['subnav-active']">
            Password
          </a>
          <a mat-tab-link [routerLink]="'/user-settings/email-subscriptions'"
             [routerLinkActive]="['subnav-active']">
            Email Subscriptions
          </a>
          <a mat-tab-link [routerLink]="'/user-settings/security'"
             [routerLinkActive]="['subnav-active']">
            Security
          </a>
          <a mat-tab-link [routerLink]="'/user-settings/arches-settings'"
             [routerLinkActive]="['subnav-active']">
            Arches Settings
          </a>
          <a *ngIf="currentAccountEntityGroupName === 'affiliates'"
            mat-tab-link href="{{environment.classicUrl}}/affiliate/notifications.php"
             [routerLinkActive]="['subnav-active']">
            Classic Notifications
          </a>
          <a *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             mat-tab-link href="{{environment.classicUrl}}/merchant_all/notifications.php"
             [routerLinkActive]="['subnav-active']">
            Classic Notifications
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'accountSettings'">
          <a *ngIf="(permissions.manage_merchant_relationships && currentAccountEntityGroupName === 'affiliates')
          || (currentAccountEntityGroupName === 'merchants' && permissions.merchant_access)
          || (currentAccountEntityGroupName === 'merchant_groups' && permissions.merchant_access)"
             mat-tab-link [routerLink]="'/account-settings/account-profile'"
             [routerLinkActive]="['subnav-active']">
            Account Profile
          </a>
          <a *ngIf="(permissions.create_users && currentAccountEntityGroupName === 'affiliates')
            || ((currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups')
            && permissions.create_users)"
             mat-tab-link [routerLink]="'/account-settings/manage-users'"
             [routerLinkActive]="['subnav-active']">
            Manage Users
          </a>
          <a *ngIf="permissions.create_users && currentAccountEntityGroupName === 'root'"
             mat-tab-link href="#" class="non-link" [disableRipple]="true">
            Manage Users
          </a>
          <a mat-tab-link *ngIf="permissions.create_users && currentAccountEntityGroupName === 'affiliates'"
             href="{{environment.classicUrl}}/affiliate/edit_websites.php"
             [routerLinkActive]="['subnav-active']">
            Secondary Websites
          </a>
          <a mat-tab-link *ngIf="permissions.view_reports && currentAccountEntityGroupName === 'affiliates'" href="{{environment.classicUrl}}/affiliate/tag_group_admin.php"
             [routerLinkActive]="['subnav-active']">
            Tag Groups
          </a>
          <a mat-tab-link *ngIf="permissions.create_users && currentAccountEntityGroupName === 'affiliates'"
             [routerLink]="'/account-settings/avantlink-networks'"
             [routerLinkActive]="['subnav-active']">
            AvantLink Networks
          </a>
          <a mat-tab-link *ngIf="(currentAccountEntityGroupName === 'merchants' && permissions.merchant_access)
            || (currentAccountEntityGroupName === 'merchant_groups' && permissions.merchant_access)"
             href="{{environment.classicUrl}}/merchant_all/edit_program_terms.php"
             [routerLinkActive]="['subnav-active']">
            Program Terms
          </a>
          <a mat-tab-link *ngIf="(currentAccountEntityGroupName === 'merchants' && permissions.merchant_access)
            || (currentAccountEntityGroupName === 'merchant_groups' && permissions.merchant_access)"
             [routerLink]="'/account-settings/email-templates'"
             [routerLinkActive]="['subnav-active']">
            Email Templates
          </a>
          <a mat-tab-link *ngIf="(currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups')"
             href="{{environment.classicUrl}}/merchant_all/contract_list.php"
             [routerLinkActive]="['subnav-active']">
            Contracts
          </a>
          <a mat-tab-link *ngIf="(currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups')"
             routerLink="/account-settings/integration"
             [routerLinkActive]="['subnav-active']">
            Integration
          </a>
          <a mat-tab-link *ngIf="(currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups')"
             href="{{environment.classicUrl}}/merchant_all/order_restrict_ip.php"
             [routerLinkActive]="['subnav-active']">
            IP Restrictions
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'commissionTools'">
          <a mat-tab-link
             *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             href="{{environment.classicUrl}}/merchant_all/affiliate_bonus.php"
             [routerLinkActive]="['subnav-active']">
            Bonus
          </a>
          <a mat-tab-link
             *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             href="{{environment.classicUrl}}/merchant_all/affiliate_commission_promotion.php"
             [routerLinkActive]="['subnav-active']">
            Commission Promotion
          </a>
          <a mat-tab-link
             *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             href="{{environment.classicUrl}}/merchant_all/item_based_commission.php"
             [routerLinkActive]="['subnav-active']">
            Item Based Commission
          </a>
          <a mat-tab-link
             *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             href="{{environment.classicUrl}}/merchant_all/tag_group_admin.php"
             routerLinkActive="['subnav-active']">
            Tag Groups
          </a>
          <a mat-tab-link
            *ngIf="(currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups') && currentAccountEntityNetwork === 'US'"
            href="{{environment.classicUrl}}/merchant_all/sub_affiliate_commission.php"
            routerLinkActive="['subnav-active']">
            Sub Affiliate Partner
          </a>
          <a mat-tab-link
             *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             href="{{environment.classicUrl}}/merchant_all/network_settings.php"
             routerLinkActive="['subnav-active']">
            Network Rules
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'transactionManager'">
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/order_select.php'}"
             href="{{environment.classicUrl}}/merchant_all/order_select.php">
            Returns
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/order_manual_entry.php'}"
             href="{{environment.classicUrl}}/merchant_all/order_manual_entry.php">
            Sales
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
              [routerLink]="'/transaction-manager/batch-returns-sales'" [routerLinkActive]="['subnav-active']">
            Batch Returns & Sales
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/order_inquiry_list.php'}"
             href="{{environment.classicUrl}}/merchant_all/order_inquiry_list.php">
            Order Inquiry
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/order_search.php'}"
             href="{{environment.classicUrl}}/merchant_all/order_search.php">
            Order Search
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             [ngClass]="{'subnav-active': location.href | includes: '/merchant_all/tracking_parser.php'}"
             href="{{environment.classicUrl}}/merchant_all/tracking_parser.php">
            Tracking Details
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'adTools'">
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'affiliates'
          || currentAccountEntityGroupName === 'merchants' || currentAccountEntityGroupName === 'merchant_groups'"
             class="non-link" [disableRipple]="true" [routerLink]="'/ad-tools'">
            Ad Tools
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'discounts'">
          <a mat-tab-link class="non-link" [disableRipple]="true" [routerLink]="'/ad-tools/discounts'">
            Discounts
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'adManagement'">
          <a mat-tab-link class="non-link" [disableRipple]="true" [routerLink]="'/ad-tools/ad-management'">
            Ad Management
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'customLinkBuilder'">
          <a mat-tab-link class="non-link" [disableRipple]="true" [routerLink]="'/ad-tools/custom-link-builder'">
            Custom Link Builder
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'easyLink'">
          <a mat-tab-link class="non-link" [disableRipple]="true" [routerLink]="'/ad-tools/easy-link'">
            Easy Link
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'productLink'">
          <a mat-tab-link class="non-link" [disableRipple]="true" [routerLink]="'/ad-tools/product-link'">
            Product Link
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'qrCode'">
          <a mat-tab-link class="non-link" [disableRipple]="true" [routerLink]="'/ad-tools/qr-code'">
            QR Code Builder
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'notificationManagement'">
          <a mat-tab-link class="non-link" [disableRipple]="true" href="#">
            Notification Management
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'manageUsers'">
          <a mat-tab-link class="non-link" [disableRipple]="true" [routerLink]="'/account-settings/manage-users'">
            Manage Users
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'communication'">
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'root'" [routerLink]="'/communication-tools/email-moderation'"
             [routerLinkActive]="['subnav-active']">
            Email Moderation
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'root'" href="{{environment.classicUrl}}/admin/moderation_queue.php">
            Tag Group Rule Moderation
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'root'" [routerLink]="'/communication-tools/notifications'"
             [routerLinkActive]="['subnav-active']">
            Notifications
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'root'" [routerLink]="'/communication-tools/network-emails'"
             [routerLinkActive]="['subnav-active']">
            Network Email
          </a>
          <a mat-tab-link *ngIf="currentAccountEntityGroupName === 'root'" [routerLink]="'/communication-tools/email-templates'"
             [routerLinkActive]="['subnav-active']">
            Email Templates
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'partners'">
          <a mat-tab-link class="non-link" [disableRipple]="true" [routerLink]="'/partners/email-partners'">
            Email Partners
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'accessDenied'">
          <a mat-tab-link class="non-link" [disableRipple]="true" [routerLink]="'/access-denied'">
            Access Denied
          </a>
        </ng-container>

        <ng-container *ngSwitchCase="'404Page'">
          <a mat-tab-link class="non-link" [disableRipple]="true" [routerLink]="'/404'">
            404 Page Not Found
          </a>
        </ng-container>
      </ng-container>
    </ng-template>
</div>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
