import {Component, inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

export interface LinkInfo {displayText: string; hrefUrl: string}

@Component({
    selector: 'app-persistent-snack-bar',
    templateUrl: './persistent-snack-bar.component.html',
    styleUrl: './persistent-snack-bar.component.scss',
    standalone: false
})
export class PersistentSnackBarComponent {
  snackBarRef = inject(MatSnackBarRef);
  data: {message: string; linkInfo: LinkInfo} = inject(MAT_SNACK_BAR_DATA);
}
