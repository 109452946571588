import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './not-found.component';
import { UnsubscribeReportComponent } from './unsubscribe-report/unsubscribe-report/unsubscribe-report.component';
import {AuthGuardData, EntityGroupGuardData} from './core/services/guards/guards';
import {
  featureFlagGuard,
  entityGroupGuard,
  agencyGuard,
  authGuard,
  logoutGuard
} from './core/services/guards/can-activate.guards';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'dashboard/affiliate',
    loadChildren: () => import('./snapshots/affiliate-snapshot/affiliate-snapshot.module').then(m => m.AffiliateSnapshotModule),
    canActivate: [entityGroupGuard],
    data: <EntityGroupGuardData> { validEntityGroups: ['affiliates'] }
  },
  {
    path: 'dashboard/merchant',
    loadChildren: () => import('./snapshots/merchant-snapshot/merchant-snapshot.module').then(m => m.MerchantSnapshotModule),
    canActivate: [entityGroupGuard],
    data: <EntityGroupGuardData> { validEntityGroups: ['merchants', 'merchant_groups'] }
  },
  {
    path: 'dashboard/agency',
    loadChildren: () => import('./snapshots/agency-snapshot/agency-snapshot.module').then(m => m.AgencySnapshotModule),
    canActivate: [entityGroupGuard, agencyGuard],
    data: <EntityGroupGuardData> { validEntityGroups: ['merchants', 'merchant_groups']}
  },
  {
    path: 'dashboard/key-accounts',
    loadChildren: () => import('./snapshots/agency-snapshot/agency-snapshot.module').then(m => m.AgencySnapshotModule),
    canActivate: [entityGroupGuard],
    data: <EntityGroupGuardData> { validEntityGroups: ['root']}
  },
  {
    path: 'dashboard/admin',
    loadChildren: () => import('./snapshots/admin-snapshot/admin-snapshot.module').then(m => m.AdminSnapshotModule),
    canActivate: [entityGroupGuard],
    data: <EntityGroupGuardData> { validEntityGroups: ['root'] }
  },
  { path: 'reports',
    canActivate: [featureFlagGuard, authGuard],
    data: {
      featureFlag: 'showEdw3Reports',
      checkPermission: ['entity_reports']
    },
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
  {
    path: 'user-settings',
    loadChildren: () => import('./user-settings/user-settings.module').then(m => m.UserSettingsModule),
    canActivate: [authGuard],
    data: <AuthGuardData> {checkPermission: ['entity_account_settings']}
  },
  {
    path: 'account-settings',
    loadChildren: () => import('./account-settings/account-settings.module').then(m => m.AccountSettingsModule),
    canActivate: [authGuard],
    data: <AuthGuardData> {
      checkPermission: ['manage_merchant_relationships', 'merchant_access', 'business_settings', 'view_reports'],
      multiplePermissionOperator: 'OR'
    }
  },
  {
    path: 'partners',
    loadChildren: () => import('./partners/partners.module').then(m => m.PartnersModule),
    canActivate: [authGuard],
    data: <AuthGuardData> {
      checkPermission: ['merchant_access']
    }
  },
  {
    path: 'ad-tools',
    loadChildren: () => import('./tool/tool.module').then(m => m.ToolModule),
    canActivate: [authGuard],
    data: <AuthGuardData> {
      checkPermission: ['configure_tools', 'merchant_access']
    }
  },
  {
    path: 'communication-tools',
    canActivate: [entityGroupGuard],
    data: <EntityGroupGuardData> { validEntityGroups: ['root'] },
    loadChildren: () => import('./communication-tools/communication-tools.module').then(m => m.CommunicationToolsModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notification-terms/notification-terms.module').then(m => m.NotificationTermsModule)
  },
  {
    path: 'transaction-manager',
    loadChildren: () => import('./transaction-manager/transaction-manager.module').then(m => m.TransactionManagerModule)
  },
  {
    path: 'affiliate-application-verification',
    loadChildren: () => import('./allow-anonymous/affiliate-social-media-auth/affiliate-social-media-auth.module').then(m => m.AffiliateSocialMediaAuthModule)
  },
  {
    path: 'logout',
    canActivate: [logoutGuard],
    children: []
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: 'unsubscribe-report/:userId/:reportId/:reportSubscriptionId/:authorizationToken',
    component: UnsubscribeReportComponent
  },
  {
    path: 'shopify',
    loadChildren: () => import('./allow-anonymous/shopify-app/shopify-app.module').then(m => m.ShopifyAppModule)
  },
  {
    path: 'tracking-test-link',
    loadChildren: () => import('./allow-anonymous/tracking-test-link/tracking-test-link.module').then(m => m.TrackingTestLinkModule)
  },
  {
    path: 'admin/merchant-tools',
    loadChildren: () => import('./merchant-admin-tools/integration-form/integration-form.module').then(m => m.IntegrationFormModule)
  },
  // Redirects from old url
  { path: 'ad-management', redirectTo: 'ad-tools/ad-management'},
  { path: 'user/settings', redirectTo: '/user-settings/profile', pathMatch: 'full' },
  { path: 'user/settings/profile', redirectTo: '/user-settings/profile', pathMatch: 'full' },
  { path: 'user/settings/password', redirectTo: '/user-settings/password', pathMatch: 'full' },
  { path: 'user/settings/security', redirectTo: '/user-settings/security', pathMatch: 'full' },
  { path: 'user/settings/reports', redirectTo: '/user-settings/arches-settings', pathMatch: 'full' },
  { path: 'snapshot/affiliate', redirectTo: 'dashboard/affiliate', pathMatch: 'full' },
  { path: 'snapshot/merchant', redirectTo: 'dashboard/merchant', pathMatch: 'full' },
  { path: 'dashboard/key_accounts', redirectTo: 'dashboard/key-accounts', pathMatch: 'full'},
  { path: 'business_settings', redirectTo: 'account-settings'},
  { path: 'entity_settings', redirectTo: 'account-settings'},
  { path: 'account_settings', redirectTo: 'account-settings'},
  { path: 'tool', redirectTo: 'ad-tools' },
  { path: 'ad_tools', redirectTo: 'ad-tools'},
  { path: 'transaction_manager', redirectTo: 'transaction-manager'},
  { path: 'unsubscribe_report', redirectTo: 'unsubscribe-report' },
  { path: 'affiliate-application-authentication', redirectTo: 'affiliate-application-verification' },
  { path: 'notifications/management', redirectTo: 'communication-tools/notifications' },
  { path: 'commission-tools/coupon-code', redirectTo: 'ad-tools/discounts'},
  { path: 'report', redirectTo: 'reports'},
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {}
    )
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {}
